<template>
  <div class="imageCard-Box" style="padding: 0;" @click="click">
    <el-card class="image-card-container" :body-style="{ padding: '0px'}">
      <el-image :src="image" fit="cover" :alt="description" :preview-src-list="checkIfShowPreview"></el-image>
      <template v-if="showOptions === true">
        <div class="options-container">
          <el-button @click.stop.prevent="showInfo" type="info" round plain>Opciones</el-button>
          <el-button @click.stop.prevent="remove" type="danger" icon="el-icon-delete" circle plain></el-button>
        </div>
      </template>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'ImageCard',
  props: {
    showPreview: {
      required: false,
      type: Boolean,
      default: true
    },
    showOptions: {
      required: false,
      type: Boolean,
      default: false
    },
    id: {
      required: false,
      type: String,
      default: '0'
    },
    image: {
      required: true,
      type: String
    },
    description: {
      required: false,
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  computed: {
    checkIfShowPreview () {
      let list = []
      if (this.showPreview) {
        list = [this.image]
      } else if (!this.showPreview) {
        list = []
      }
      return list
    }
  },
  mounted () {
    this.getLog()
  },
  methods: {
    remove () {
      this.$emit('remove')
    },
    showInfo () {
      this.$emit('info', this.id)
    },
    getLog () {
      console.log('Tarjeta Renderizada')
    },
    click () {
      this.$emit('clickOnCard')
    }
  }
}
</script>

<style lang="scss" scoped>
.image-card-container {
  position: relative;
  // width: 10.8em;
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  .el-image{
    position: relative;
    width: 100%;
  }
  .options-container{
    justify-content: space-around;
    width: 100%;
    padding: 0.8em 0 0.6em 0;
    background: rgb(51,51,51);
    background: -moz-linear-gradient(180deg, rgba(51,51,51,0) 2%, rgba(0,0,0,0.5116421568627452) 53%);
    background: -webkit-linear-gradient(180deg, rgba(51,51,51,0) 2%, rgba(0,0,0,0.5116421568627452) 53%);
    background: linear-gradient(180deg, rgba(51,51,51,0) 2%, rgba(0,0,0,0.5116421568627452) 53%);
    position: absolute;
    bottom: 0em;
    transition: opacity ease-in 0.2s;
    -moz-transition: opacity ease-in 0.2s;
    -webkit-transition: opacity ease-in 0.2s;
    z-index: 2;
    opacity: 0;
  }

 &:hover .options-container{
  opacity: 1;
 }

 &:focus .options-container{
  opacity: 1;
 }
}
@media screen and (max-width: 720px) {
  .image-card-container{
    width: 8rem;
    .options-container{
      opacity: 1;
    }
  }
}
</style>
