<template>
  <div class="upload-image-component">
    <div v-if="getImageFromProps!== null && getImageFromProps !== ''">
      <el-image
        class="el-upload-list__item-thumbnail"
        style="width: 150px; height: 150px"
        :src="`${host + getImageFromProps}`"
        alt=""
        fit="scale-down">
      </el-image>
    </div>
    <template v-else>
      <div class="icon-empty-image" align="center">
        <el-image style="width: 100%; height: 45%;" alt="No hay imagen seleccionada">
          <div slot="error" class="image-slot">
            <i style="font-size: 3.8em;" class="el-icon-warning-outline"></i>
          </div>
        </el-image>
        <p>No hay Imagen</p>
      </div>
    </template>
    <el-button @click="loadOptionsImages" style="margin-top: 0.6em;" type="success" round plain>Seleccionar imagen</el-button>
    <!---- Ventana de opciones de subida de imagen -->
    <el-dialog width="80%" title="Selecciona una opción" :visible.sync="showImageUploadOptions">
      <div class="upload-btn-float">
        <el-upload
          style="margin-top: 1em;"
          v-loading="loadingImage"
          :show-file-list="false"
          action="#"
          list-type="picture"
          :http-request="postImage"
          :before-upload="beforeImageUpload">
          <el-button size="small" type="primary">Subir de computadora</el-button>
        </el-upload>
      </div>
      <el-row class="gallery-section" justify="center" align="middle">
        <el-col v-for="(item, index) in listImages" :key="index" :lg="4" :md="6" :sm="6" :xs="12" align="middle">
          <ImageCard
            @clickOnCard="selectImage(item.path)"
            class="select-card"
            :showOptions="false"
            :showPreview="false"
            :key="item._id"
            :id="item._id"
            :image="host + item.path"
            :description="item.description">
          </ImageCard>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
// import { search } from '@/api/gallery.js'
import ImageCard from '@/components/ImageCard/ImageCard.vue'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'ImageUpload',
  components: {
    ImageCard
  },
  props: {
    setImage: {
      default: null,
      type: String,
      required: false
    },
    getImagesFrom: {
      type: Function,
      required: true
    },
    postImageTo: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      host: urlServer,
      listImages: [],
      loadingImage: false,
      imageUploaded: false,
      showImageUploadOptions: false,
      imageLoaded: null
    }
  },
  computed: {
    getImageFromProps () {
      let image = null
      if (this.imageLoaded !== null && this.imageLoaded !== '') {
        image = this.imageLoaded
      } else {
        image = this.setImage
      }
      return image
    }
  },
  mounted () {
  },
  methods: {
    selectImage (urlImage) {
      this.imageLoaded = urlImage
      this.showImageUploadOptions = false
      this.$emit('getImage', this.imageLoaded)
    },
    loadOptionsImages () {
      this.showImageUploadOptions = true
      this.loadGallery()
    },
    async loadGallery () {
      console.log('Cargando galeria')
      await this.getImagesFrom({ query: '' })
        .then((response) => {
          console.log(response)
          this.listImages = response.data.data
          console.log(this.listImages)
        })
        .catch(this.responseCatch)
        .finally(() => (console.log('Galeria cargada')))
    },
    // --- Upload of Images -----
    beforeImageUpload (file) {
      console.log(file)
      const LENGTH_IMG = 2000
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2000K = file.size / 1024 < LENGTH_IMG

      if (!isJPG && !isPNG) {
        this.$message.error('La imagen debe estar en formato JPG o PNG!')
        return false
      }
      if (!isLt2000K) {
        this.$message.error('La imagen excede los 900kB!')
        return false
      }
      return true
    },
    async postImage (param) {
      // this.loading = true
      const metadata = {
        randomSelect: false,
        categories: []
      }
      const formData = new FormData()
      console.log(param)
      formData.append('image', param.file)
      formData.append('title', 'Imagen de Galería')
      formData.append('alt', 'Descripción vacía')
      formData.append('folder', 'images')
      formData.append('metadata', JSON.stringify(metadata))
      formData.append('alt', 'Imagen de Manager Probosque')
      this.postImageTo(formData)
        .then((response) => {
          console.log(response.data)
          this.imageLoaded = response.data.path
          this.$emit('getImage', this.imageLoaded)
          this.showImageUploadOptions = false
        })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-btn-float {
  position: absolute;
  top: 2em;
  z-index: 1;
}

.gallery-section{
  max-height: 60vh;
  overflow: scroll;
}

.select-card{
  cursor: pointer;
  transition: all 0.4ms;
}
.select-card:hover{
  opacity: 1;
}

.icon-empty-image {
  padding-top: 1.6em;
  text-align: center;
  width: 150px;
  height: 150px;
  border-radius: 0.4em;
  border: 1px dashed rgb(152, 152, 152);
  overflow: hidden;
  p {
    margin: 0;
  }
}
</style>
