import request from '@/utils/request'
export function search (params) {
  return request({
    url: '/api-manager/image/search',
    method: 'post',
    params
  })
}
export function getPage (page, noitems, params) {
  return request({
    url: '/api-manager/image/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function getImage (id) {
  return request({
    url: `/api-manager/image/${id}`,
    method: 'get'
  })
}

export function createImage (data) {
  return request({
    url: '/api-manager/image',
    method: 'post',
    data
  })
}

export function updateImage (data, id) {
  return request({
    url: `/api-manager/image/${id}`,
    method: 'put',
    data
  })
}

export function deleteImage (id) {
  return request({
    url: `/api-manager/image/${id}`,
    method: 'delete'
  })
}
