import request from '@/utils/request'
export function search (params) {
  return request({
    url: '/api-manager/project/search',
    method: 'post',
    params
  })
}
export function getPage (page, noitems, params) {
  return request({
    url: '/api-manager/project/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function getProject (id) {
  return request({
    url: `/api-manager/project/${id}`,
    method: 'get'
  })
}

export function createProject (data) {
  return request({
    url: '/api-manager/project',
    method: 'post',
    data
  })
}

export function updateProject (data, id) {
  return request({
    url: `/api-manager/project/${id}`,
    method: 'put',
    data
  })
}

export function deleteProject (id) {
  return request({
    url: `/api-manager/project/${id}`,
    method: 'delete'
  })
}

export function existDefault () {
  return request({
    url: '/api-manager/project/exist/default',
    method: 'get'
  })
}
